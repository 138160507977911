<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          ref="searchWrapper"
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Record_number')" prop="parkRecordId">
                <el-input
                  v-model.trim="formInline.parkRecordId"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  maxlength="19"
                  placeholder="记录编号"
                >
                </el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader
                  @change="handleAreaChange"
                  ref="cascader"
                ></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_name')">
                <el-autocomplete
                  class="inline-input"
                  v-model="modelvalue"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入道路名称"
                  value-key="parkName"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Equipment_number')" prop="hardCode">
                <el-input
                  v-model.trim="formInline.hardCode"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.time_slot')">
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.Berth_number')">
                <el-input
                  placeholder="请输入泊位号"
                  maxlength="30"
                  v-model="formInline.berthCode"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber" ref="plateNumber">
                <el-autocomplete
                  ref="plateNumber"
                  size="11"
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsyncPlateNumber"
                  placeholder="车牌号"
                  :maxlength="8"
                  :trigger-on-focus="false"
                  @select="handleSelectPlateNumber"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Presence_situation')" prop="" ref="">
                <el-select v-model.trim="formInline.recordState" placeholder="">
                  <el-option
                    :label="item.label"
                    :value="item.value"
                    :key="item.value"
                    v-for="item in recordStateList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :label="$t('searchModule.Interactive_effect')"
                prop=""
                ref=""
                class="interaction-box"
              >
                <el-select v-model.trim="formInline.reportType" placeholder="">
                  <el-option
                    :label="item.label"
                    :value="item.value"
                    :key="item.value"
                    v-for="item in reportTypeList"
                  ></el-option>
                </el-select>
                <el-select
                  v-model.trim="formInline.reportResult"
                  placeholder=""
                >
                  <el-option
                    :label="item.label"
                    :value="item.value"
                    :key="item.value"
                    v-for="item in reportResultList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="" ref="" class="">
                <el-select
                  v-model.trim="formInline.reportDelayType"
                  placeholder=""
                  class="reportDelayType-box"
                >
                  <el-option
                    :label="item.label"
                    :value="item.value"
                    :key="item.value"
                    v-for="item in reportDelayTypeList"
                  ></el-option>
                </el-select>
                <el-input
                  v-model.trim="formInline.reportDelaySecTime"
                  placeholder="请输入时间"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  class="time-input"
                ></el-input>
                <el-select
                  v-model.trim="formInline.timeType"
                  placeholder=""
                  class="timeType-box"
                >
                  <el-option
                    :label="item.label"
                    :value="item.value"
                    :key="item.value"
                    v-for="item in timeTypeList"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="$route.meta.authority.button.export"
          ></div>
          <div class="col_box h44" v-if="$route.meta.authority.button.export">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <el-button
                type="info"
                v-if="$route.meta.authority.button.export"
                plain
                icon="el-icon-upload2"
                @click="exportFile"
                >导出
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <div
          class="statistics-box"
          v-show="
            statisticsData.parkRecordTotal ||
            statisticsData.entryReportSuccessTotal ||
            statisticsData.exitReportSuccessTotal ||
            statisticsData.entryReportExceTotal ||
            statisticsData.exitReportExceTotal ||
            statisticsData.exitReprotNotTotal ||
            statisticsData.entryReportFailTotal ||
            statisticsData.exitReportFailTotal
          "
        >
          <span v-show="statisticsData.parkRecordTotal"
            >停车记录共
            {{ statisticsData.parkRecordTotal || 0 }} 条&nbsp;&nbsp;</span
          >
          <span v-show="statisticsData.entryReportSuccessTotal"
            >入场交互成功
            {{ statisticsData.entryReportSuccessTotal || 0 }}
            条&nbsp;&nbsp;</span
          >
          <span v-show="statisticsData.exitReportSuccessTotal"
            >出场交互成功
            {{ statisticsData.exitReportSuccessTotal || 0 }}
            条&nbsp;&nbsp;</span
          >
          <span v-show="statisticsData.entryReportExceTotal"
            >入场交互异常
            {{ statisticsData.entryReportExceTotal || 0 }} 条&nbsp;&nbsp;</span
          >
          <span v-show="statisticsData.exitReportExceTotal"
            >出场交互异常
            {{ statisticsData.exitReportExceTotal || 0 }} 条&nbsp;&nbsp;</span
          >
          <span v-show="statisticsData.exitReprotNotTotal"
            >未进行出场交互
            {{ statisticsData.exitReprotNotTotal || 0 }} 条&nbsp;&nbsp;</span
          >
          <span v-show="statisticsData.entryReportFailTotal"
            >入场交互失败
            {{ statisticsData.entryReportFailTotal || 0 }} 条&nbsp;&nbsp;</span
          >
          <span v-show="statisticsData.exitReportFailTotal"
            >出场交互失败
            {{ statisticsData.exitReportFailTotal || 0 }} 条&nbsp;&nbsp;</span
          >
        </div>
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column :label="$t('list.operation')" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleClickDetail(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper" v-show="total > 0">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <high-detail
          :parkName="parkName"
          :propsRecordId="recordId"
          :recordIdList="recordIdList"
          ref="highDetail"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
import highDetail from "./components/detail";
import timeRangePick from "@/components/timePicker";
export default {
  data() {
    return {
      parkName: "",
      recordId: "",
      recordIdList: [],
      modelvalue: "",
      areaIds: "",
      pageSize: 15,
      pageNum: 1,
      loading: false,
      total: 0,
      tableData: [],
      tableCols: [
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
          width: "100px",
        },
        {
          prop: "parkRecordId",
          label: this.$t("list.Record_number"),
          width: "",
        },
        {
          prop: "hardCode",
          label: this.$t("list.Equipment_number"),
          width: "",
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_No"),
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "100px",
        },
        {
          prop: "carTypeDesc",
          label: this.$t("list.Vehicle_type"),
          width: "",
        },
        {
          prop: "entryTimeStr",
          label: this.$t("list.entry_time"),
          width: "160px",
        },
        {
          prop: "exitTimeStr",
          label: this.$t("list.Appearance_time"),
          width: "160px",
        },
        {
          prop: "entryDelaySecStr",
          label: this.$t("list.Admission_reporting_delay"),
          width: "110px",
        },
        {
          prop: "exitDelaySecStr",
          label: this.$t("list.Appearance_reporting_delay"),
          width: "110px",
        },
        {
          prop: "entryReportDesc",
          label: this.$t("list.Entry_interaction_results"),
          width: "110px",
        },
        {
          prop: "exitReportDesc",
          label: this.$t("list.Appearance_interaction_result"),
          width: "110px",
        },
        {
          prop: "entryReportDelaySecStr",
          label: this.$t("list.Entry_interaction_delay"),
          width: "110px",
        },
        {
          prop: "exitReportDelaySecStr",
          label: this.$t("list.Appearance_interaction_delay"),
          width: "110px",
        },
      ],
      statisticsData: {},
      initFormInline: {},
      formInline: {
        parkRecordId: "",
        areaId: "",
        parkId: "",
        hardCode: "",
        entryTimeStart: "",
        entryTimeEnd: "",
        berthCode: "",
        reportType: "",
        reportResult: "",
        reportDelayType: "3",
        reportDelaySec: "",
        reportDelaySecTime: "",
        timeType: "2",
        recordState: "",
        plateNumber: "",
        carId: "",
      },
      reportTypeList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "入场",
          value: "0",
        },
        {
          label: "出场",
          value: "1",
        },
      ],
      reportResultList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "交互成功",
          value: "0",
        },
        {
          label: "交互异常",
          value: "1",
        },
        {
          label: "交互失败",
          value: "2",
        },
      ],
      reportDelayTypeList: [
        {
          label: this.$t("list.Admission_reporting_delay"),
          value: "0",
        },
        {
          label: this.$t("list.Appearance_reporting_delay"),
          value: "1",
        },
        {
          label: this.$t("list.Entry_interaction_delay"),
          value: "2",
        },
        {
          label: this.$t("list.Appearance_interaction_delay"),
          value: "3",
        },
      ],
      timeTypeList: [
        {
          label: "分钟",
          value: "1",
        },
        {
          label: "小时",
          value: "2",
        },
        {
          label: "天",
          value: "3",
        },
      ],
      recordStateList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "未离场",
          value: "1",
        },
        {
          label: "已离场",
          value: "0",
        },
      ],
    };
  },
  created() {
    this.defalutDate = [
      this.dataTimeRest() + " 00:00:00",
      this.dataTimeRest() + " 23:59:59",
    ];
    this.formInline.entryTimeStart = this.defalutDate[0];
    this.formInline.entryTimeEnd = this.defalutDate[1];
    this.searchData();
  },
  mounted() {
    // this.$refs.highDetail.dialogVisible = true
  },
  methods: {
    // 详情
    handleClickDetail(row) {
      this.parkName = row.parkName;
      this.recordId = row.dtsRecordId;
      let recordIdList = [];
      this.tableData.forEach((ele) => {
        recordIdList.push(ele.dtsRecordId);
      });
      this.$set(this, "recordIdList", recordIdList);
      this.$refs.highDetail.dialogVisible = true;
    },
    // 分页处理
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      if (this.formInline.plateNumber == "") {
        this.formInline.carId = "";
      }
      this.searchInit();
      let data = JSON.parse(JSON.stringify(this.formInline));
      data.areaId = "";
      if (this.$refs.cascader) {
        data.areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.$axios
        .get("/acb/2.0/dtsRecord/queryByList", {
          data: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            ...data,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            const data = res.value;
            this.tableData = data.pageResult.list;
            this.total = data.pageResult.total * 1;
            const statisticsData = data;
            delete statisticsData.pageResult;
            this.$set(this, "statisticsData", statisticsData);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 搜索条件初始化
    searchInit() {
      this.formInline.parkName = this.modelvalue;
      if (this.formInline.reportDelaySecTime) {
        let Num = "",
          reportDelaySecTime = Number(this.formInline.reportDelaySecTime);
        if (this.formInline.timeType == 1) {
          Num = 60;
        } else if (this.formInline.timeType == 2) {
          Num = 3600;
        } else if (this.formInline.timeType == 3) {
          Num = 3600 * 24;
        }
        this.formInline.reportDelaySec = reportDelaySecTime * Num;
      } else {
        this.formInline.reportDelaySec = "";
      }
    },
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.areaIds = areaId;
    },
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1,2",
            dataSource: 1,
            areaIds: areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.temParkData = res.value.list.length ? res.value.list[0] : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            // this.parkData = res.value.list;
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    timeChange(timeArr) {
      this.formInline.entryTimeStart = timeArr[0];
      this.formInline.entryTimeEnd = timeArr[1];
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10
          ? "0" + (nowDate.getMonth() + 1)
          : nowDate.getMonth() + 1;
      let day =
        nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    querySearchAsyncPlateNumber(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    handleSelectPlateNumber(item) {
      this.loading = false;
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData();
    },
    resetForm() {
      const formInline = {
        parkRecordId: "",
        areaId: "",
        parkId: "",
        hardCode: "",
        entryTimeStart: "",
        entryTimeEnd: "",
        berthCode: "",
        reportType: "",
        reportResult: "",
        reportDelayType: "3",
        reportDelaySec: "",
        reportDelaySecTime: "",
        timeType: "2",
        recordState: "",
        plateNumber: "",
        carId: "",
      };
      this.$set(this, "formInline", formInline);
      this.$refs.timeRangePicker.resetTime();
      this.$refs.cascader.clear();
      this.modelvalue = "";
      this.areaIds = "";
    },
    exportFile() {
      this.searchInit();
      let opt = {};
      let data = JSON.parse(JSON.stringify(this.formInline));
      data.areaId = "";
      if (this.$refs.cascader) {
        data.areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      opt = {
        ...data,
      };
      exportExcelNew("/acb/2.0/dtsRecord/export", opt);
    },
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        // this.temParkData = {};
      }
    },
  },
  components: {
    timeRangePick,
    highDetail,
  },
};
</script>
<style lang="stylus" scoped>
.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    // padding: 22px 22px 0px;
    // margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }

  .interaction-box {
    margin-right: 20px;
  }

  .statistics-box {
    padding: 20px 0 20px 20px;

    span {
      display: inline-block;
      font-size: 14px;
      margin-right: 10px;
    }
  }

  ::v-deep .el-form-item.interaction-box {
    .el-input__inner {
      min-width: 110px !important;
    }
  }

  ::v-deep .el-select.reportDelayType-box {
    .el-input__inner {
      min-width: 140px !important;
    }
  }

  ::v-deep .time-input.el-input {
    .el-input__inner {
      min-width: 140px !important;
    }
  }

  /deep/ {
    .interaction-box {
      .el-select {
        width: 110px;

        &:first-child {
          margin-right: 6px;
        }
      }
    }

    .reportDelayType-box {
      width: 140px;
    }

    .time-input {
      width: 130px;
      margin: 0 6px;
    }

    .timeType-box {
      width: 90px;
    }
  }
}

::v-deep .el-select.timeType-box {
  margin-left: 10px;

  .el-input__inner {
    min-width: 90px;
  }
}
</style>
