<template>
  <el-dialog
    :title="parkName"
    :visible.sync="dialogVisible"
    width="72%"
    class="dialog-box"
  >
    <div class="phone-box">
      <div class="phone-tab">
        <el-radio-group v-model="phoneType">
          <el-radio-button v-if="entryPic.length" label="1">入场照片</el-radio-button>
          <el-radio-button v-if="exitPic.length" label="2">出场照片</el-radio-button>
        </el-radio-group>
      </div>
      <div class="phone-main">
        <div v-if="phoneType == 1" class="clear-f">
          <div
            class="picBox"
            v-for="(value, i) in entryPic"
            :key="i"
            v-show="value.imageType != 22"
          >
            <div class="title">
              {{
                value.imageType == 1
                  ? "全景照片"
                  : value.imageType == 2
                  ? "车辆照片"
                  : value.imageType == 3
                  ? "车牌照片"
                  : value.imageType == 4
                  ? "入场视频"
                  : ""
              }}
            </div>
            <!-- <div v-if="value.imageType == 4" class="pic">
                          <video style="width: 100%; height: 100%; object-fit: fill;" controls>
                            <source src="http://vjs.zencdn.net/v/oceans.mp4" type="video/mp4">
                          </video>
                        </div> -->
            <!-- <div v-else class="pic"> -->
            <div class="pic">
              <div
                @click="showBigPic(value.picUrl)"
                v-if="value.imageType == 1"
                :style="{
                  width: '100%',
                  height: '100%',
                  background:
                    'white url(' + value.picUrl + ') center/contain no-repeat',
                }"
              ></div>
              <img
                v-if="value.imageType != 1"
                :src="value.picUrl"
                width="100%"
                height="100%"
                @click="showBigPic(value.picUrl)"
              />
            </div>
          </div>
        </div>
        <div v-else-if="phoneType == 2" class="clear-f">
          <div
            class="picBox"
            v-for="(value, i) in exitPic"
            :key="i"
            v-show="value.imageType != 22"
          >
            <div class="title">
              {{
                value.imageType == 1
                  ? "全景照片"
                  : value.imageType == 2
                  ? "车辆照片"
                  : value.imageType == 3
                  ? "车牌照片"
                  : value.imageType == 4
                  ? "入场视频"
                  : ""
              }}
            </div>
            <!-- <div v-if="value.imageType == 4" class="pic">
                          <video style="width: 100%; height: 100%; object-fit: fill;" controls>
                            <source src="http://vjs.zencdn.net/v/oceans.mp4" type="video/mp4">
                          </video>
                        </div> -->
            <!-- <div v-else class="pic"> -->
            <div class="pic">
              <div
                @click="showBigPic(value.picUrl)"
                v-if="value.imageType == 1"
                :style="{
                  width: '100%',
                  height: '100%',
                  background:
                    'white url(' + value.picUrl + ') center/contain no-repeat',
                }"
              ></div>
              <img
                v-if="value.imageType != 1"
                :src="value.picUrl"
                width="100%"
                height="100%"
                @click="showBigPic(value.picUrl)"
              />
            </div>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div
          class="mask"
          v-show="bigPic"
          :style="{ 'z-index': '10000', 'background-image': picUrl }"
          @click="bigPic = false"
        ></div>
      </transition>
    </div>
    <div class="information-box">
      <div class="information-line" v-for="(group, i) in informationList" :key="i">
        <div class="information-title fl">{{ group.title }}</div>
        <div class="information-main fl">
          <div v-for="(item, j) in group.list" :key="j" class="main-item" :class="item.isOnLine ? 'one-line' : ''"><span :class="item.isWeight ? 'fw600' : ''">{{ item.name }}</span>{{ detailData[item.key] }}</div>
        </div>
      </div>
    </div>
    <div class="footer-box">
      <el-button type="primary" :disabled="recordIndex == 0" :loading="loading" class="first-btn" @click="handleOperation('1')">上一条</el-button>
      <el-button type="primary" :disabled="recordIndex == recordIdList.length - 1" :loading="loading" @click="handleOperation('2')">下一条</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    parkName: {
      type: String,
      default: "详情",
    },
    propsRecordId: {
      type: String,
      default: "",
    },
    recordIdList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      recordIndex: '',
      recordId: '',
      loading: false,
      dialogVisible: false,
      phoneType: 1,
      entryPic: [],
      exitPic: [],
      bigPic: false,
      picUrl: "",
      detailData: {},
      informationList: [
        {
          title: '基本信息',
          list: [
            {
              name: '车牌号：',
              key: 'plateNumber',  // key值
              isWeight: true,  // 是否加粗字体
            },
            {
              name: '泊位号：',
              key: 'berthCode',
              isWeight: true,
            },
            {
              name: '车辆类型：',
              key: 'carTypeDesc',
              isWeight: true,
            },
          ]
        },
        {
          title: '入场信息',
          list: [
            {
              name: '入场时间：',
              key: 'entryTimeStr',
              isWeight: true,
              isOnLine: true  // 是否占一行空间 
            },
            {
              name: '入场上报时间：',
              key: 'entryOCRTimeStr',
            },
            {
              name: '上报延时：',
              key: 'entryDelaySecStr',
            },
            {
              name: '图片上报时间：',
              key: 'entryPicReportTime',
            },
            {
              name: '图片延时：',
              key: 'entryPicReportDelaySecStr',
            },
            {
              name: '同步第三方入场：',
              key: 'entryReportTime',
            },
            {
              name: '同步第三方返回描述：',
              key: 'entryReportDesc',
            },
            {
              name: '交互延时：',
              key: 'entryReportDelaySecStr',
            },
            // {
            //   name: '车牌修改返回描述：',
            //   key: 'test',
            //   isOnLine: true  // 是否占一行空间 
            // },
            // {
            //   name: '泊位修改返回描述：',
            //   key: 'test',
            //   isOnLine: true  // 是否占一行空间 
            // },
          ]
        },
        {
          title: '出场信息',
          list: [
            {
              name: '出场时间：',
              key: 'exitTimeStr',
              isWeight: true,
              isOnLine: true  // 是否占一行空间 
            },
            {
              name: '出场上报时间：',
              key: 'exitOCRTimeStr',
            },
            {
              name: '上报延时：',
              key: 'exitDelaySecStr',
            },
            {
              name: '图片上报时间：',
              key: 'exitPicReportTime',
            },
            {
              name: '图片延时：',
              key: 'exitPicReportDelaySecStr',
            },
            {
              name: '同步第三方出场：',
              key: 'exitReportTime',
            },
            {
              name: '同步第三方返回描述：',
              key: 'exitReportDesc',
            },
            {
              name: '交互延时：',
              key: 'exitReportDelaySecStr',
            },
          ]
        }
      ]
    };
  },
  methods: {
    showBigPic(url) {
      if (!url) {
        return false;
      }
      this.picUrl = `url("${url}")`;
      this.bigPic = true;
    },
    handleGetData() {
      this.recordIndex = this.recordIdList.indexOf(this.recordId)
      this.loading = true;
      this.$axios.get("/acb/2.0/dtsRecord/detail", {
        data: {
          dtsRecordId: this.recordId
        }
      }).then(res => {
        this.loading = false
        if (res.state == 0) {
          this.detailData = res.value;
          this.handleInitImg(res.value)
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      }).catch(err => {
        this.loading = false
      })
    },
    handleInitImg(data) {
      let entryPic = [], exitPic = [];
      if (data.entryFullPic) {  // 入场全景图
        entryPic.push({
          imageType: 1,
          picUrl: data.entryFullPic
        })
      }
      if (data.entryFeaturePic) {  // 入场车辆照片
        entryPic.push({
          imageType: 2,
          picUrl: data.entryFeaturePic
        })
      }
      if (data.entryPlatePic) {  // 入场车牌照片
        entryPic.push({
          imageType: 3,
          picUrl: data.entryPlatePic
        })
      }
      if (data.exitFullPic) {  // 出场全景图
        exitPic.push({
          imageType: 1,
          picUrl: data.exitFullPic
        })
      }
      if (data.exitFeaturePic) {  // 出场车辆照片
        exitPic.push({
          imageType: 2,
          picUrl: data.exitFeaturePic
        })
      }
      if (data.exitPlatePic) {  // 出场车牌照片
        exitPic.push({
          imageType: 3,
          picUrl: data.exitPlatePic
        })
      }
      this.entryPic = entryPic;
      this.exitPic = exitPic;
    },
    handleOperation(type) {
      if (type == 1) {
        this.recordId = this.recordIdList[this.recordIndex - 1]
      } else if (type == 2) {
        this.recordId = this.recordIdList[this.recordIndex + 1]
      }
      this.handleGetData()
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.bigPic = false
        this.recordId = this.propsRecordId
        this.handleGetData()
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to { /* .fade-leave-active in <2.1.8 */
  opacity: 0;
}

.mask {
  background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.dialog-box {
    /deep/.el-dialog {
      margin-top: 5vh !important;
    }
  
  /deep/ .el-dialog__header {
    padding: 0 12px;
    background-color: #3F4A56;
    height: 40px;
    line-height: 40px;
    border-radius: 3px 3px 0 0;

    .el-dialog__title {
      font-size: 14px;
      color: #fff;
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #ffffff;
      transform: translateY(-50%);
    }
  }
  /deep/ {
    .el-dialog__body {
      padding: 20px;
      max-height: 78vh;
    overflow: auto;
    }
  }

  .phone-box {
    .phone-tab {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .clear-f {
    overflow: hidden;
  }
  .footer-box {
    text-align: center;
    .first-btn {
      margin-right: 10px;
    }
  }
}

.picBox {
  float: left;
  width: 33.33333%;
  padding: 0 20px 15px 0;
  box-sizing: border-box;
}

.title {
  font-family: MicrosoftYaHei;
  font-size: 13px;
  color: #8091A5;
  letter-spacing: 0;
  line-height: 16px;
}

.pic {
  // background: url('') center no-repeat;
  width: 100%;
  height: 150px;
  border: 1px solid #8091A5;
  margin-top: 10px;
  overflow: hidden;
  cursor: pointer;
}
.information-box {
  line-height: 1.2;
  color: #566475;
  font-size: 14px;
  .fl {
    float: left;
  }
  .w33 {
    width: 33.33%;
  }
  .fw600 {
    font-weight: 600;
  }
  .information-title {
    width: 100px;
    height: 30px;
    line-height: 30px;
  }
  .information-line {
    width: 100%;
    overflow: hidden;
    margin-top: 10px;
  }
  .information-main {
    width: calc(100% - 100px);
    overflow: hidden;
  }
  .main-item {
    float: left;
    width: 33.33%;
    height: 30px;
    line-height: 30px;
    color: rgba(0, 0, 0, .65);
    &.one-line {
      width: 100%;
    }
    span {
      display: inline-block;
      width: 148px;
      font-weight: 600;
      &.fw600 {
        color: #22252F;
      }
    }
  }
}
</style>
